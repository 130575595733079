<template>
  <div>
    <feather-icon title="Edit Extra" icon="Edit2Icon" svgClasses="h-5 w-5 mr-3 hover:text-primary cursor-pointer" @click="onEdit" />
    <feather-icon title="View Extra" icon="EyeIcon" svgClasses="h-5 w-5 mr-3 hover:text-primary cursor-pointer" @click="onView" />
    <feather-icon title="Edit Extra" icon="TrashIcon" svgClasses="h-5 w-5 mr-0 hover:text-primary cursor-pointer" @click="showDeletePopup=true" />
    <vs-prompt
      title="Delete Extra"
      accept-text="Delete"
      @accept="onDelete"
      :active.sync="showDeletePopup">

        <div class="vx-row mt-2 mb-2">
          <div class="vx-col w-full">
            <div class="">Are you sure you want to delete the extra - {{params.data.extraName}}?</div>
          </div>
        </div>
    </vs-prompt>
  </div>
</template>

<script>
import vSelect from 'vue-select'

export default {
  components: {
    vSelect
  },
  data () {
    return {
      showDeletePopup: false
    }
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    }
  },
  methods: {
    onEdit () {
      this.params.context.componentParent.onEdit(this.params.data.id) 
    },
    onView () {
      this.params.context.componentParent.onView(this.params.data.id) 
    },
    onDelete () {
      this.params.context.componentParent.onDelete(this.params.data.id) 
    },
  }
}
</script>
